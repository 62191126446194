import React, { useEffect, useState } from "react";
import { tableHeads } from "../../constants";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "../../components/common";
import { getExpenses } from "../../actions";
import { display } from "../../components/common/round";
import { createPortal } from "react-dom";
import { useOutletContext } from "react-router-dom";
import { formatNumber } from "../../helpers/formatNumber";

function List() {
  const setOpen = useOutletContext(); //function that we pass as a props in Outlet in index.js file
  const dispatch = useDispatch();
  let { expenses = [] } = useSelector(({ expenses }) => expenses);
  const { allExpenses = [] } = useSelector(({ expenses }) => expenses);
  const [showModal, setShowModal] = useState(false);
  const [receiptDocuments, setReceiptDocuments] = useState(null); //array of selected receipt documents
  const [documentIndex, setDocumentindex] = useState(0);

  const amount =
    allExpenses &&
    allExpenses?.rows
      ?.map((val) => {
        return val.amount;
      })
      .reduce((a, b) => a + b, 0);

  useEffect(() => {
    dispatch(getExpenses());
  }, []);

  const handleModal = (data) => {
    setReceiptDocuments(data);
    setShowModal(true);
  };

  return (
    <div className="tab-content">
      <div className="tab-pane fade active show" id="kt_stats_widget_6_tab_1">
        <div className="table-responsive">
          <div class="card-title flex-column">
            <h3 class="fw-bold mb-1">Driver Expenses</h3>

            <div className="d-flex flex-wrap justify-content-start">
              <div class="d-flex flex-wrap">
                <div
                  className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                  style={{ marginTop: 5 }}
                >
                  <div className="d-flex align-items-center">
                    <div className="fs-6 text-gray-500 fw-bold">
                      Total Expense
                    </div>
                  </div>
                  <div
                    className="fs-2 fw-bold text-danger"
                    style={{ textAlign: "center", paddingTop: 5 }}
                  >
                    {/* {amount ? display(amount) : 0} */}
                    ${formatNumber(amount) || 0}
                  </div>
                </div>
                <div
                  className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                  style={{ marginTop: 5 }}
                >
                  <div className="d-flex align-items-center">
                    <div className="fs-6 text-gray-500 fw-bold">
                      List of Expenses
                    </div>
                  </div>
                  <div
                    className="fs-2 fw-bold text-success"
                    style={{ textAlign: "center", paddingTop: 5 }}
                  >
                    {formatNumber(allExpenses.count || 0)}
                  </div>
                </div>

                <div
                  className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                  style={{ marginTop: 5 }}
                >
                  <div className="d-flex align-items-center">
                    <div className="fs-6 text-gray-500 fw-bold">
                      Total Unpaid
                    </div>
                  </div>
                  <div
                    className="fs-2 fw-bold"
                    style={{
                      textAlign: "center",
                      paddingTop: 5,
                      color: "#f0416c",
                    }}
                  >
                    {
                     formatNumber( 
                      allExpenses?.rows?.filter(
                      (row) => row.status === "unpaid").length
                     )
                    }
                  </div>
                </div>

                <div
                  className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                  style={{ marginTop: 5 }}
                >
                  <div className="d-flex align-items-center">
                    <div className="fs-6 text-gray-500 fw-bold">Total New</div>
                  </div>
                  <div
                    className="fs-2 fw-bold"
                    style={{
                      textAlign: "center",
                      paddingTop: 5,
                      color: "#7339ea",
                    }}
                  >
                    {
                     formatNumber(
                      allExpenses?.rows?.filter((row) => row.status === "new")
                      .length
                     )
                    }
                  </div>
                </div>

                <div
                  className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
                  style={{ marginTop: 5 }}
                >
                  <div className="d-flex align-items-center">
                    <div className="fs-6 text-gray-500 fw-bold">Total Paid</div>
                  </div>
                  <div
                    className="fs-2 fw-bold"
                    style={{
                      textAlign: "center",
                      paddingTop: 5,
                      color: "#75cc67",
                    }}
                  >
                    {
                      formatNumber(
                        allExpenses?.rows?.filter((row) => row.status === "paid")
                        .length
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr />
          <Table
            lable="expenses"
            tableRows={allExpenses?.rows}
            tableHeads={tableHeads}
            handleModal={handleModal}
            setOpen={setOpen?.length ? setOpen[0] : ""}
          />
        </div>
      </div>
      {showModal &&
        createPortal(
          <div
            style={{
              position: "fixed",
              left: "",
              top: "0",
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              background: "rgba(0, 0, 0, 0.2)",
              zIndex: 150,
            }}
            onClick={() => {
              setShowModal(false);
              setReceiptDocuments(null);
              setDocumentindex(0);
            }}
          >
            <div
              style={{
                width: "40%",
                height: "70%",
                background: "white",
                borderRadius: "20px",
                padding: "30px",
                display: "flex",
                flexDirection: "column",
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <label
                className="fw-bold fs-6 text-gray-700"
                style={{ textAlign: "center" }}
              >
                {` Driver: ${receiptDocuments?.driver?.firstName} ${receiptDocuments?.driver?.lastName}`}
              </label>

              <div style={{ width: "100%", height: "80%", margin: "auto" }}>
                <div className="image-input image-input-outline w-100 h-100">
                  {/*begin::Preview existing avatar*/}
                  <img
                    className="image-input-wrapper w-100 h-100"
                    src={`${process.env.REACT_APP_API_BASE_URL_IMAGE}${receiptDocuments?.receiptDocuments[documentIndex]}`}
                  />
                </div>
              </div>

              <div
                style={{
                  width: "100%",
                  margin: "auto",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <button
                  type="button"
                  name="description"
                  className="btn btn-primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setDocumentindex(documentIndex - 1);
                  }}
                  disabled={documentIndex === 0}
                >
                  {" "}
                  Prev
                </button>

                <div class="fs-6 fw-semibold text-gray-700 py-2">
                  {" "}
                  {receiptDocuments?.receiptDocuments?.length &&
                    `Showing ${1 + 1 * documentIndex} of ${
                      receiptDocuments?.receiptDocuments?.length
                    } entries`}
                </div>

                <button
                  type="button"
                  name="description"
                  className="btn btn-primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    setDocumentindex(documentIndex + 1);
                  }}
                  disabled={
                    documentIndex ===
                    receiptDocuments?.receiptDocuments?.length - 1
                  }
                  // onClick={handleShowAll}
                >
                  {" "}
                  Next
                </button>
              </div>
            </div>
          </div>,
          document.body
        )}
    </div>
  );
}

export default List;
