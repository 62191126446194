import React, { useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Selector, Table, ViewDatePicker } from "../../components/common";
import { fetchDrivers,getDriversInOutData,getLoggedInDrivers } from "../../actions";
import { NavLink, Outlet, useOutletContext } from "react-router-dom";
import Modal from 'react-modal';
import { GoogleMap, Marker, LoadScript,useJsApiLoader } from '@react-google-maps/api';
import PaginationControlled from "../../components/common/paginate";
import InputSelect from "../../components/common/InputSelect";
import { formatNumber } from "../../helpers/formatNumber";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: '50%',
    bottom: '-7%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};


const tableHeads1 = ["Driver Name","Login Area","Logout Area","Login Time","Logout Time","Runing Status","Hours Worked","Edit","Hide"]


function TrackingDataList({ width = "100%" }) {
  const dispatch = useDispatch()
  const [setActive] = useOutletContext()
  const {driversInOutData,page=1} = useSelector(({ driver }) => driver);

  // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('yourAppElement');

useEffect(()=>{
  setActive(1)
  dispatch(getDriversInOutData())
 },[])

  return (
    <>
        <div className="card-body">
       <div className="tab-content">
          <div className="tab-pane fade active show" id="kt_stats_widget_6_tab_1">
            <div className="table-responsive">
              <div className="d-flex flex-wrap justify-content-start">
                <div class="d-flex flex-wrap">
                  <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                    <div className="d-flex align-items-center">
                      <div className="fs-6 text-gray-500 fw-bold">
                      Tracking Data
                      </div>
                    </div>
                    <div className="fs-2 fw-bold text-success" style={{ textAlign: "center", paddingTop: 5 }}>
                      {formatNumber(driversInOutData?.count || 0)}
                    </div>
                  </div>
                </div>
              </div>
              <Table
                lable="DriverTrackingData"
                tableHeads={tableHeads1}
                tableRows={driversInOutData?.result}
              />
            </div>
          </div>
        </div>
        </div>
    </>
  );
}


export default TrackingDataList
