import React,{useEffect} from 'react'
import {user, userType} from "../../constants"
import {useSelector,useDispatch} from "react-redux";
import {fetchAllAdmins} from "../../actions"
import {Table} from "../../components/common"
import { formatNumber } from '../../helpers/formatNumber';


function UserList() {
    const dispatch = useDispatch();
    const {allAdmins, admin} = useSelector(({admin})=>admin)
    useEffect(()=>{
      dispatch(fetchAllAdmins())
    },[])
    
  return (
    
    <div className="tab-content">
    <div className="tab-pane fade active show" id="kt_stats_widget_6_tab_1">
      <div className="table-responsive">
        <div class="card-title flex-column">
          <div className="d-flex flex-wrap justify-content-start">
					<div class="d-flex flex-wrap">
            <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3" style={{marginTop:5}}>
								<div className="d-flex align-items-center">
								<div className="fs-6 text-gray-500 fw-bold" >List of Users</div>
									</div>
									<div className="fs-2 fw-bold text-success" style={{textAlign:"center", paddingTop:5}}>{formatNumber(allAdmins?.length || 0)}</div>
								</div>
                </div>
                </div>
        </div>        
        <hr />
        <Table lable="users" tableRows={allAdmins} tableHeads={user}/>
      </div>
      
    </div>
    
  </div>
  
  )
}

export default UserList