import React, { useEffect, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Selector, Table, ViewDatePicker } from "../../components/common";
import { fetchDrivers,getDriversInOutData,getLoggedInDrivers } from "../../actions";
import { NavLink, Outlet, useOutletContext } from "react-router-dom";
import Modal from 'react-modal';
import { GoogleMap, Marker, LoadScript,useJsApiLoader } from '@react-google-maps/api';
import PaginationControlled from "../../components/common/paginate";
import InputSelect from "../../components/common/InputSelect";
import { formatNumber } from "../../helpers/formatNumber";

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: '50%',
    bottom: '-7%',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const tableHeads = ["Driver Name", "Location", "Last Location Time"];



function TrackingList() {
  const [setActive] = useOutletContext()
  const {loggedInDrivers,page=1} = useSelector(({ driver }) => driver);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getLoggedInDrivers())
  }, []);

  const handleSearch = ()=> {
    dispatch(getLoggedInDrivers())
  }

  // Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('yourAppElement');

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    if(subtitle?.style){
      subtitle.style.color = '#f00';
    }
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(()=>{
   setActive(0)
  },[])
  // const center = {
  //   lat: 30.7046,
  //   lng: 76.7179
  // };

  const [center, setCenter] = React.useState({lat:0, lng:0})
  const containerStyle = {
    width: '100%',
    height: '400px'
  };
  
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['geometry', 'drawing'],
  });
  return (
    <>
        <div className="card-body">
           <div className="tab-content">
            <div className="tab-pane fade active show" id="kt_stats_widget_6_tab_1">
              <div className="table-responsive">
                <div className="d-flex flex-wrap justify-content-start">
                  <div class="d-flex flex-wrap">
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                      <div className="d-flex align-items-center">
                        <div className="fs-6 text-gray-500 fw-bold">
                        Logged in Drivers
                        </div>
                      </div>
                      <div className="fs-2 fw-bold text-success" style={{ textAlign: "center", paddingTop: 5 }}>
                        {formatNumber(loggedInDrivers?.count || 0)}
                      </div>
                    </div>
                  </div>
                </div>
                <Table
                  lable="DriverTrackingList"
                  tableHeads={tableHeads}
                  tableRows={loggedInDrivers?.rows}
                  setIsOpen={setIsOpen}
                  setCenter={setCenter}
                />
              </div>
            </div>
          </div>
        </div>

      <div id="yourAppElement">
        {/* <button onClick={openModal}>Open Modal</button> */}
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <span onClick={closeModal} style={{float: 'right'}}><i class="bi bi-x fs-2" title="Location"></i></span>
          {modalIsOpen && 
          isLoaded &&
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={15}>
                <Marker position={center} />
              </GoogleMap>
            }
        </Modal>
      </div>
    </>
  );
}


export default TrackingList
