import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { formatNumber } from "../../../helpers/formatNumber";
const counts = [0,1,3,5,7,9,11,12,14,17]

const styles = {borderRight:"1px solid", fontSize: "13px"}

const DriverPackageInvoiceTable = ()=>{
    const { driverPackageInvoice, loader } = useSelector(({ driverPackageInvoices }) => driverPackageInvoices);
    
    return (
        <div className="table-responsive" style={{padding: "0 -20px"}}>
            <table
                className="table table-hover table-row-dashed table-row-gray-300 align-middle listab"
                id="lastab"
                style={{ padding: "5px"}}
                width="100%"
                cellSpacing={1}
                cellPadding={0}
                border={1}
            >
                <thead style={{ backgroundColor: "black", color:"#fff"}}>
                <tr className="fw-bold border-bottom-0">
                    <td rowSpan={2} width="6%" className="min-w-100px" style={{...styles, borderBottom: "1px solid gray"}} >
                        <div style={{textAlign: "center", marginTop:"-75px"}}>
                        Date
                        </div>
                    </td>
                    <td colSpan={2} width="6%" className="min-w-100px" style={styles}>
                    Package A
                    </td>
                    <td colSpan={2} width="6%" className="min-w-100px" style={styles}>
                    Package B
                    </td>
                    <td colSpan={2} width="6%" className="min-w-100px" style={styles}>
                    Package D
                    </td>
                    <td colSpan={2} width="6%" className="min-w-100px" style={styles}>
                    Package E
                    </td>
                    <td colSpan={2} width="6%" className="min-w-100px" style={styles}>
                    Package F
                    </td>
                    <td rowSpan={2} style={{...styles, borderBottom: "1px solid gray", }} >Total Count</td>
                    <td colSpan={2} width="6%" className="min-w-100px" style={styles}>
                    Package G
                    </td>
                    <td colSpan={2} width="6%" className="min-w-100px" style={styles}>
                    Package H
                    </td>
                    <td rowSpan={2} style={{...styles,borderBottom: "1px solid gray"}} border={1}>Total Amount</td>
                   <td rowSpan={2} style={{...styles,borderBottom: "1px solid gray", paddingRight:"5px"}} border={1}>Hours Worked</td>     
                </tr>
                <tr>
                    {[0,0,0,0,0,0,0].map((it,i)=>(
                        <>
                        <td style={{border: '1px solid #fff',fontSize: "12px"}}>Count</td>
                        <td style={{border: '1px solid #fff',fontSize: "12px"}}>Driver Pay</td>
                        </>
                    ))}
                </tr>
                </thead>
                <tbody>
                {driverPackageInvoice?.data?.length ? driverPackageInvoice.data.map((row,i)=> 
                i!==driverPackageInvoice?.data?.length-1 ?
                <tr key={i} style={{fontSize: "12px"}}>
                    <td style={{padding: "2px"}} width={1}>
                    {`${new Date(row[0]?.date).getDate()}/${new Date(row[0]?.date).getMonth()+1}/${new Date(row[0]?.date).getFullYear()}`}
                    </td>
                    <td style={{fontSize:"12px"}} width={6}>{formatNumber(row[0]?.count || 0)}</td>
                    <td style={{fontSize:"12px"}} width={6}>${formatNumber(row[0]?.driverPay || 0)}</td>
                    <td style={{fontSize:"12px"}} width={6}>{formatNumber(row[1]?.count || 0)}</td>
                    <td style={{fontSize:"12px"}} width={6}>${formatNumber(row[1]?.driverPay || 0)}</td>
                    <td style={{fontSize:"12px"}} width={6}>{formatNumber(row[2]?.count || 0)}</td>
                    <td style={{fontSize:"12px"}} width={6}>${formatNumber(row[2]?.driverPay || 0 )}</td>
                    <td style={{fontSize:"12px"}} width={6}>{formatNumber(row[3]?.count || 0)}</td>
                    <td style={{fontSize:"12px"}} width={6}>${formatNumber(row[3]?.driverPay || 0)}</td>
                    <td style={{fontSize:"12px"}} width={6}>{formatNumber(row[4]?.count || 0)}</td>
                    <td style={{fontSize:"12px"}} width={6}>${formatNumber(row[4]?.driverPay || 0)}</td>
                    <td style={{fontSize:"12px"}} width={6}>{formatNumber(row[4]?.horizontalAFCountTotal || 0)}</td>
                    <td style={{fontSize:"12px"}} width={6}>{formatNumber(row[5]?.count || 0)}</td>
                    <td style={{fontSize:"12px"}} width={6}>${formatNumber(row[5]?.driverPay || 0)}</td>
                    <td style={{fontSize:"12px"}} width={6}>{formatNumber(row[6]?.count || 0)}</td>
                    <td style={{fontSize:"12px"}} width={6}>${formatNumber(row[6]?.driverPay || 0)}</td>
                    <td style={{fontSize:"12px"}} width={6}>${formatNumber(row[6]?.horizontalGrandTotal || 0)}</td>
                    <td style={{fontSize:"12px"}} width={6}>{formatNumber(row[0]?.totalWorkingHours || 0)}</td>
                </tr>
                :null
                ):null}
                </tbody>
                <tfoot>
                    <tr>
                    {driverPackageInvoice?.data?.length ? driverPackageInvoice.data[driverPackageInvoice?.data?.length-1].map((col,i)=>
                    <td key={i} colSpan={1} width="6%"  style={{fontSize:"12px"}}>
                        {/* {JSON.stringify(counts)} */}
                        {i === 0 ? 
                        `Total`:  
                        counts.includes(i)? Number.isFinite(col) ? formatNumber(col): col :`$${formatNumber(col)}`}
                    </td>
                    ):null}
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}
export default DriverPackageInvoiceTable;