import React, { useMemo, useEffect, useState } from "react";
import { Selector } from "../../components/common";
import TableIncome from "./driverPackageIncomeTable";
import TableIncome2 from "./driverPackageIncomeTable2";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchDrivers,
  fetchHiddenDriver,
  getDriverPackageIncome,
} from "../../actions";
import { NotificationManager } from "react-notifications";
import { useFormik } from "formik";
import * as yup from "yup";
import { ACTION_TYPES } from "../../constants";
import { ViewDatePicker } from "../../components/common";
import { CSVLink } from "react-csv";
import Loader from "../../components/common/loader";

import html2canvas from "html2canvas";
import jsPdf from "jspdf";
import { NavLink, useNavigate } from "react-router-dom";
import InputSelect from "../../components/common/InputSelect";
import { createDriverPackageInvoice } from "../../actions/driverPackageInvoices";
import { formatNumber } from "../../helpers/formatNumber";

const generatePDF = (setLoading) => {
  setLoading(true);
  const domElement = document.getElementById("report");
  html2canvas(domElement, {}).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPdf({
      orientation: "p",
      unit: "pt",
      format: [canvas.width, canvas.height],
    });
    pdf.addImage(imgData, "JPEG", 0, 0, canvas.width, canvas.height);
    pdf.save(`${new Date().toISOString()}.pdf`);
    setLoading(false);
  });
};

function DriverPackageIncome() {
  const styles = {
    padding: "1em",
    backgroundColor: "#fff",
  };

  let [loading, setLoading] = useState(false);
  const { options } = useSelector(({ area }) => area);
  let { hiddenDrivers = [], allDrivers = [] } = useSelector(({ driver }) => driver);
  const { lastState, driverPackageIncome = [] } = useSelector(
    ({ reports }) => reports
  );
  const dispatch = useDispatch();
  const navigate =  useNavigate();

  const driver = useMemo(() => {
    if (!allDrivers?.length) return [];
    return allDrivers.filter((it) => it.id == lastState?.driverId);
  }, [driverPackageIncome]);

  useEffect(() => {
    dispatch(fetchDrivers());
  }, []);

  const data = [
    driverPackageIncome?.length
      ? driverPackageIncome
          .slice(0, driverPackageIncome?.length - 1)
          .map((value) => {
            return (
              {
                " Date": "",
                " Package A": "Count",
                " ": "Driver Pay",
                " Package B": "Count",
                "": "Driver Pay",
                " Package D": "Count",
                "  ": "Driver Pay",
                " Package E": "Count",
                "   ": "Driver Pay",
                " Package F": "Count",
                "    ": "Driver Pay",
                TotalCount: "",
                " Package G": "Count",
                "     ": "Driver Pay",
                " Package H": "Count",
                "      ": "Driver Pay",
                TotalAmount: "",
              },
              {
                Date: value[0].date,
                " Package A": value[0]?.count || 0,
                " ": value[0]?.driverPay || 0,
                " Package B": value[1]?.count || 0,
                "": value[1]?.driverPay || 0,
                " Package D": value[2]?.count || 0,
                "  ": value[2]?.driverPay || 0,
                " Package E": value[3]?.count || 0,
                "   ": value[3]?.driverPay || 0,
                " Package F": value[4]?.count || 0,
                "    ": value[4]?.driverPay || 0,
                TotalCount: value[4]?.horizontalAFCountTotal || 0,
                " Package G": value[5]?.count || 0,
                "     ": value[5]?.driverPay || 0,
                " Package H": value[6]?.count || 0,
                "      ": value[6]?.driverPay || 0,
                TotalAmount: value[6]?.horizontalGrandTotal || 0,
              }
            );
          })
      : "",
  ];


  const initialValues = {
    startDate: lastState?.startDate
      ? lastState?.startDate
      : new Date().toISOString(),
    endDate: lastState?.endDate ? lastState?.endDate : new Date().toISOString(),
    areaId: lastState?.areaId ? lastState?.areaId : options[0]?.id || 1,
    driverId: lastState?.driverId ? lastState?.driverId : driver[0]?.id || 1,
  };

  const validation = yup.object({
    startDate: yup.date().required("Please fill startDate"),
    endDate: yup.date().required("Please fill endDate"),
    areaId: yup.string().required("Please select area"),
    driverId: yup.string().required("Please select driver"),
  });

  const handleSubmit = (values, { resetForm }) => {
    if (values) {
      dispatch(getDriverPackageIncome(values));
      dispatch({ type: ACTION_TYPES.SAVE_STATE, payload: values });
    } else NotificationManager.error("please fill data");
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validation,
    onSubmit: handleSubmit,
  });

  const generateDriverPackageInvoice = ()=>{
    if(!formik.values.driverId){
      NotificationManager.error('Driver required before generating driver package invoice')
      return 
    }
    else if(!driverPackageIncome.length){
      NotificationManager.error('No driver package record found!')
      return
    }

    const total_amount = driverPackageIncome[driverPackageIncome?.length - 1][driverPackageIncome[driverPackageIncome?.length - 1].length - 2]
    const total_hours = driverPackageIncome[driverPackageIncome?.length - 1][driverPackageIncome[driverPackageIncome?.length - 1].length - 1]

    const payload = {
      driver_id: formik.values.driverId,
      data: driverPackageIncome,
      total_amount: total_amount,
      hours_worked: total_hours
    }
    dispatch(createDriverPackageInvoice(payload, navigate))
  }


  return (
    <div id="kt_app_content" className="app-content flex-column-fluid">
      {/* pdf generation */}
      <div
        style={{
          opacity: 0,
          fontSize: 0.1,
          height: "0px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <ul className="nav nav-pills nav-pills-custom mb-3">
            <li className="nav-item mb-3 me-3 me-lg-6">
              <button
                style={{ float: "left", marginLeft: 10, marginTop: "-30px" }}
                type="submit"
                name="description"
                className="btn btn-primary"
                id="kt_account_profile_details_submit"
                onClick={generatePDF}
              >
                {" "}
                Download Pdf
              </button>
            </li>
          </ul>
        </div>
        <div id="report">
          <div id="kt_app_content" className="app-content flex-column-fluid">
            <div
              id="kt_app_content_container"
              className="app-container container-xxl"
            >
              <div className="card">
                <div className="card-body py-20">
                  <div className="mw-lg-950px mx-auto w-100">
                    <div className="d-flex justify-content-between flex-column flex-sm-row mb-19">
                      <h4 className="fw-bolder text-gray-800 fs-2qx pe-5 pb-7">
                        Tax Invoice#
                      </h4>
                      <div className="text-sm-end">
                        <NavLink className="d-block mw-150px ms-sm-auto">
                          <img
                            alt="Logo"
                            src="/assets/media/images/img-logo.png"
                            className="w-100"
                          />
                        </NavLink>
                        <div className="text-sm-end fw-semibold fs-4 text-muted mt-7">
                          <div>Address will be placed here</div>
                          <div> St. Mina Transport Pty Ltd</div>
                          <div>ABN 81 136 635 848</div>
                        </div>
                      </div>
                    </div>

                    <div className="pb-12">
                      <div className="d-flex flex-column gap-7 gap-md-10">
                        <div className="fw-bold fs-2">
                          {driver?.length
                            ? `${driver[0]?.firstName} ${driver[0]?.lastName}`
                            : null}
                          <br />
                          <span className="text-muted fs-5">
                           {`ABN ${driver[0]?.abn || "N/A"}`}
                          </span>
                        </div>
                        <div className="separator"></div>
                        <div className="d-flex flex-column flex-sm-row gap-7 gap-md-10 fw-bold">
                          <div className="flex-root d-flex flex-column">
                            <span className="text-muted">Date created</span>
                            <span className="fs-5">
                              {new Date().toDateString()}
                            </span>
                          </div>
                          {/* <div className="flex-root d-flex flex-column">
                          <span className="text-muted">Area</span>
                          <span className="fs-5">
                            {}
                          </span>
                        </div> */}
                        </div>
                        <div className="d-flex justify-content-between flex-column">
                          <hr />
                          <div
                            className="table-responsive border-bottom mb-9"
                            id="report-table"
                          >
                            <TableIncome2 />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <div className="table-responsive">
                        <div
                          className="tab"
                          style={{
                            border: "1px solid",
                            width: "300px",
                            borderRadius: "1em",
                          }}
                        >
                          <table
                            style={{
                              borderCollapse: "collapse",
                              width: "100%",
                              borderRadius: "1em",
                              overflow: "hidden",
                            }}
                          >
                            <tr>
                              <th
                                colspan="2"
                                style={{
                                  backgroundColor: "black",
                                  padding: "1em",
                                  color: "#fff",
                                  fontSize: "15px",
                                }}
                              >
                                Invoice Summary
                              </th>
                            </tr>
                            <tr>
                              <td
                                style={{
                                  backgroundColor: "white",
                                  padding: "1em",
                                  fontSize: "15px",
                                }}
                              >
                                Total Hours Worked
                              </td>
                              <td
                                style={{
                                  backgroundColor: "white",
                                  padding: "1em",
                                  fontSize: "15px",
                                }}
                              >
                                {driverPackageIncome &&
                                driverPackageIncome?.length
                                  ? driverPackageIncome[
                                      driverPackageIncome?.length - 1
                                    ][
                                      driverPackageIncome[
                                        driverPackageIncome?.length - 1
                                      ].length - 1
                                    ]
                                  : null}
                              </td>
                            </tr>
                            <tr>
                              <td style={{
                                  backgroundColor: "white",
                                  padding: "1em",
                                  fontSize: "15px",
                                }}>
                                  Total
                              </td>
                              <td style={{
                                  backgroundColor: "white",
                                  padding: "1em",
                                  fontSize: "15px",
                                }}>{
                                 driverPackageIncome?.length
                                 ? (driverPackageIncome[
                                  driverPackageIncome?.length - 1
                                ][
                                  driverPackageIncome[
                                    driverPackageIncome?.length - 1
                                  ].length - 2
                                ].toFixed(2)).toLocaleString("en-US", {style: "currency",currency: "USD",})
                                 : null}
                              </td>                       
                            </tr>
                            <tr>
                              <td style={{
                                  backgroundColor: "white",
                                  padding: "1em",
                                  fontSize: "15px",
                                }}>
                                  Total Amount (inc. GST)
                              </td>
                              <td style={{
                                  backgroundColor: "white",
                                  padding: "1em",
                                  fontSize: "15px",
                                }}>{
                                 driverPackageIncome?.length
                                 ? (driverPackageIncome[
                                  driverPackageIncome?.length - 1
                                ][
                                  driverPackageIncome[
                                    driverPackageIncome?.length - 1
                                  ].length - 2
                                ] + (+(driverPackageIncome[
                                  driverPackageIncome?.length - 1
                                ][
                                  driverPackageIncome[
                                    driverPackageIncome?.length - 1
                                  ].length - 2
                                ] * 0.1).toFixed(2))).toLocaleString("en-US", {style: "currency",currency: "USD",})
                                 : null}
                              </td>                       
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* pdf generation end */}
      <div
        id="kt_app_content_container"
        className="app-container container-xxl"
        style={{ padding: "0px !important" }}
      >
        <div className="card mb-5 mb-xl-10">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_account_profile_details"
            aria-expanded="true"
            aria-controls="kt_account_profile_details"
            style={{ padding: 0 }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="card-title m-0">
                <h3 className="fw-bold m-0">Driver/Sorter Package Income</h3>
              </div>
              <div className="d-flex">
                <div className="d-flex flex-wrap justify-content-start">
                  <div className="d-flex flex-wrap">
                    <div
                      className="min-w-125px py-3 px-4 me-6 mb-3"
                      style={{
                        marginTop: 5,
                        border: "1px dashed #DCDCDC",
                        borderRadius: "0.85rem",
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <div className="fs-6 text-gray-500 fw-bold">
                          Driver/Sorter Name
                        </div>
                      </div>
                      <div
                        className="fs-5 fw-bold text-success"
                        style={{ textAlign: "center", paddingTop: 5 }}
                      >
                        {(driver?.length && driver[0].firstName
                          ? driver[0]?.firstName
                          : "") +
                          " " +
                          (driver?.length && driver[0]?.lastName
                            ? driver[0]?.lastName
                            : "")}
                      </div>
                    </div>
                    <div
                      className="min-w-125px py-3 px-4 me-6 mb-3"
                      style={{
                        marginTop: 5,
                        border: "1px dashed #DCDCDC",
                        borderRadius: "0.85rem",
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <div className="fs-6 text-gray-500 fw-bold">
                          {" "}
                          Total Income
                        </div>
                      </div>
                      <div
                        className="fs-2 fw-bold text-success"
                        style={{ textAlign: "center", paddingTop: 5 }}
                      >
                        {`$${
                          formatNumber(driverPackageIncome?.length
                            ? driverPackageIncome[
                                driverPackageIncome?.length - 1
                              ][
                                driverPackageIncome[
                                  driverPackageIncome?.length - 1
                                ].length - 2
                              ]
                            : 0)
                        }`}
                      </div>
                    </div>
                    <div
                      className="min-w-125px py-3 px-4 me-6 mb-3"
                      style={{
                        marginTop: 5,
                        border: "1px dashed #DCDCDC",
                        borderRadius: "0.85rem",
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <div className="fs-6 text-gray-500 fw-bold">
                          {" "}
                          Total Hours
                        </div>
                      </div>
                      <div
                        className="fs-2 fw-bold text-success"
                        style={{ textAlign: "center", paddingTop: 5 }}
                      >
                        {formatNumber(driverPackageIncome?.length
                          ? driverPackageIncome[
                              driverPackageIncome?.length - 1
                            ][
                              driverPackageIncome[
                                driverPackageIncome?.length - 1
                              ].length - 1
                            ]
                          : 0)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-start py-7 px-6">
                  {data && (
                    <CSVLink
                      data={data[0]}
                      filename={"DriverPackageIncome.csv"}
                      target="_blank"
                    >
                      <button
                        type="submit"
                        name="description"
                        className="btn btn-primary"
                        id="kt_account_profile_details_submit"
                      >
                         Export as CSV
                      </button>
                    </CSVLink>
                  )}
                  <button
                    style={{ marginLeft: 10 }}
                    type="submit"
                    name="description"
                    disabled={loading}
                    className="btn btn-primary"
                    id="kt_account_profile_details_submit"
                    onClick={() => generateDriverPackageInvoice()}
                  >
                    {" "}
                    <span>Generate Invoice PDF {loading ? <Loader size={17} /> : null}{" "}</span>
                  </button>
                </div>
                {/* <div style={{margin: "0 20px",width: "200px",backgroundColor: "#DBB768",padding: "10px"}}>
              <div>
                Driver Name : {(driver?.length && driver[0].firstName? driver[0]?.firstName:"") + " " + (driver?.length && driver[0]?.lastName ? driver[0]?.lastName:"")}
              </div>
              <div>
                Total Income: {driverPackageIncome?.length ? 
                driverPackageIncome[driverPackageIncome?.length-1][driverPackageIncome[driverPackageIncome?.length-1].length-1]
                :0}
              </div>
            </div> */}
              </div>
            </div>
          </div>
          <div
            id="kt_account_settings_profile_details"
            className="collapse show"
          >
            <form
              id="kt_account_profile_details_form"
              className="form"
              onSubmit={formik.handleSubmit}
            >
              {/* {/ <div className="card-body border-top p-9"> /} */}
              <div className="card-body border-top p-9">
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Start Date
                  </label>
                  <div className="col-lg-8 fv-row">
                    <ViewDatePicker
                      name="startDate"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Date"
                      onChange={(value) =>
                        formik.setFieldValue("startDate", value.toISOString())
                      }
                      value={formik.values.startDate.substring(0, 10)}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="startDate" data-validator="notEmpty">
                      {formik.touched.startDate && formik.errors.startDate ? (
                        <div>{formik.errors.startDate}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    End Date
                  </label>
                  <div className="col-lg-8 fv-row">
                    <ViewDatePicker
                      name="endDate"
                      className="form-control form-control-lg form-control-solid"
                      placeholder="Date"
                      onChange={(value) =>
                        formik.setFieldValue("endDate", value.toISOString())
                      }
                      value={formik.values.endDate.substring(0, 10)}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div class="fv-plugins-message-container invalid-feedback">
                    <div data-field="endDate" data-validator="notEmpty">
                      {formik.touched.endDate && formik.errors.endDate ? (
                        <div>{formik.errors.endDate}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Area
                  </label>
                  <div className="col-lg-8 fv-row">
                    <Selector
                      data={options.map((it) => {
                        return {
                          value: it.id,
                          title: it.areaName,
                        };
                      })}
                      value={formik.values.areaId}
                      onBlur={formik.handleBlur}
                      onChange={(value) =>
                        formik.setFieldValue("areaId", value)
                      }
                    />
                  </div>
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="areaId" data-validator="notEmpty">
                      {formik.touched.areaId && formik.errors.areaId ? (
                        <div>{formik.errors.areaId}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row mb-6">
                  <label className="col-lg-4 col-form-label fw-semibold fs-6">
                    Driver/Sorter
                  </label>
                  <div
                    className="col-lg-8 fv-row"
                    style={{ position: "relative" }}
                  >
                    <InputSelect
                      data={
                        allDrivers.length
                          ? allDrivers.map((it) => {
                              // console.log("568", it);
                              return {
                                value: it.id,
                                title: `${it.firstName} ${it.lastName}`,
                                status: it.status,
                              };
                            })
                          : ["Loadin..."]
                      }
                      onChange={(value) =>
                        formik.setFieldValue("driverId", value)
                      }
                      value={formik.values.driverId}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="driverId" data-validator="notEmpty">
                      {formik.touched.driverId && formik.errors.driverId ? (
                        <div>{formik.errors.driverId}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              {/* </div>  */}
              <div className="card-footer d-flex justify-content-start py-6 px-9">
                <button
                  type="submit"
                  name="description"
                  className="btn btn-primary"
                  id="kt_account_profile_details_submit"
                >
                  Search
                </button>
              </div>
            </form>
          </div>
          <hr />
          <TableIncome />
        </div>
      </div>
    </div>
  );
}

export default DriverPackageIncome;
